@import "../../scss/variables";

.sidebar-toc .sidebar-toc__title {
    font-size: 1.8rem;
    text-transform: uppercase;
}

.awsui .sidebar-toc__list__item {
    border-top: 1px solid #d5dbdb;
    padding: 0;

    a {
        padding: 1.1rem 2rem;
        display: block;
        width: 100%;
        color: $awsuiBlack;

        &:focus,
        &:active,
        &:hover {
            text-decoration: none;
        }
    }

    &.is-active {
        background-color: $awsuiGrey100;
        font-weight: bold;
        border-color: #aab7b8;
    }
}
