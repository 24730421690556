@import "../../scss/variables.scss";

.console-secrets__title {
    font-size: 1.8rem;
    text-transform: uppercase;
}

.console-secrets__body {
    padding: 1rem 2rem 0;
}

.secrets-section__label {
    font-weight: bold;
}

.key-pair-list__item {
    button {
        text-decoration: underline;
    }
}

.copy-exports__body {
    display: flex;
    border: 1px solid #979797;
    border-radius: 2px;
    box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.5);
}

.copy-exports__body__input {
    flex-grow: 1;
    padding: 4px;
    color: #16191f;
    font-family: "Avenir Next";
    font-size: 14px;
    line-height: 19px;
}

.copy-exports__body__clipboard {
    flex-shrink: 0;
    flex-grow: 0;
    width: 29px;
    border-left: 1px solid #979797;
    background-color: $awsuiGrey300;
    background-image: url("clipboard.svg");
    background-repeat: no-repeat;
    background-position: center;
}
