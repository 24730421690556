@import "../../scss/variables";
@import "../../scss/mixins";

.sidebar {
    flex: 0 0 300px;
    background-color: #eaeded;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);
    transition: all 0.5s ease-in-out;
    display: flex;
    flex-direction: column;

    &.is-hidden {
        margin-left: -265px;

        .sidebar__body {
            opacity: 0;
            pointer-events: none;
        }
    }

    @include breakpoint(xl) {
        flex: 0 0 400px;

        &.is-hidden {
            margin-left: -360px;
        }
    }
}

.sidebar__header {
    display: flex;
    padding: 1.5rem 0.3rem 1rem 2rem;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #d5dbdb;
}

.sidebar__header__title {
    text-overflow: ellipsis;
    color: #16191f;
    font-size: 18px;
    text-transform: uppercase;
}

.sidebar__header__toggle {
    padding: 5px 8px;
}

.sidebar__body {
    padding: 2rem;
    flex: 0 1 100%;
    overflow-y: scroll;
    transition: opacity 0.3s ease-in-out;

    & > * + * {
        margin-top: 2rem;
    }
}
