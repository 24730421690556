.content-message {
    position: sticky;
    top: 2rem;
    margin-bottom: 2rem;
    z-index: 10;

    & > awsui-alert .awsui-alert {
        padding-top: 0.8rem;
        padding-bottom: 0.8rem;
    }
}
