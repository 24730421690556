@import "../../scss/mixins.scss";
@import "../../scss/variables.scss";

.action-button-overrides {
    font-weight: normal;
    color: $awsuiWhite;
}

.session-button,
.console-button {
    button.awsui-button.awsui-button-variant-normal,
    button.awsui-button.awsui-button-variant-normal.awsui-button-disabled {
        min-width: 140px;
        height: 30px;
        text-align: center;
    }
}

.session-button--loading {
    button.awsui-button.awsui-button-variant-normal,
    button.awsui-button.awsui-button-variant-normal.awsui-button-disabled {
        @extend .action-button-overrides;
        background-color: $labsGrey500;
    }
}
.session-button--ready {
    button.awsui-button.awsui-button-variant-normal {
        @extend .action-button-overrides;
        background-color: $labsGreen600;
    }
}
.session-button--running {
    button.awsui-button.awsui-button-variant-normal {
        @extend .action-button-overrides;
        background-color: $awsuiRed600;
    }
}
.session-button--finished {
    button.awsui-button.awsui-button-variant-normal,
    button.awsui-button.awsui-button-variant-normal.awsui-button-disabled {
        @extend .action-button-overrides;
        background-color: $labsGrey500;
    }
}
.console-button--loading {
    button.awsui-button.awsui-button-variant-normal {
        text-transform: none;
        border: 1px solid $labsGrey550;
        background-color: $awsuiWhite;
    }
}
.console-button--ready {
    button.awsui-button.awsui-button-variant-normal,
    button.awsui-button.awsui-button-variant-normal.awsui-button-disabled {
        @extend .action-button-overrides;
        background-color: $labsGrey400;
    }
}
.console-button--running {
    a,
    button.awsui-button.awsui-button-variant-normal {
        @extend .action-button-overrides;
        background-color: $awsuiGrey900;
    }
}
.console-button--finished {
    button.awsui-button.awsui-button-variant-normal,
    button.awsui-button.awsui-button-variant-normal.awsui-button-disabled {
        @extend .action-button-overrides;
        background-color: $labsGrey400;
    }
}
