.lab-content {
    flex: 0 1 100%;
    overflow-y: scroll;
    scroll-behavior: smooth;
    padding: 0 9.5rem 0 9.5rem;

    &.is-loading .lab-content__inner {
        height: 100%;
    }
}

.lab-content__inner {
    overflow: initial;
}
